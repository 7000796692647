<template>
  <transition
    enter-active-class="ease-linear duration-300 transition-all"
    enter-from-class="opacity-0"
    leave-active-class="ease-linear duration-300 transition-all"
    leave-to-class="opacity-0"
    leave-class="opacity-100"
    appear
  >
    <div
      :class="[
        'rounded-lg overflow-hidden card-sm-shadow flex flex-col cursor-pointer',
        { 'bg-bg-alternate-3': fundraiser.isPromoted },
      ]"
      @click="router.push({ name: 'Overview', params: { slug } })"
    >
      <div class="w-full relative" style="padding-top: 66.66%">
        <img
          class="w-full absolute top-0 text-2xs"
          :src="featuredImage"
          alt="fundraiser_gallery"
        />
        <span
          class="
            focus:outline-none
            absolute
            top-4
            right-3.5
            bg-bg-primary
            rounded
            px-1
            py-0.5
          "
          v-if="isOwner && showMenu"
          @click.stop="$emit('showMenu', fundraiser.id)"
        >
          <DotMenuIcon class="h-6 fill-current text-text-primary" />
        </span>
        <button
          class="focus:outline-none absolute top-4 right-3.5"
          v-else-if="canLike"
          @click.stop.prevent="handleLike"
        >
          <BButtonLoader class="h-5 w-5" v-if="isLoading" />
          <Heart2Icon
            class="h-4.5 xs:h-5"
            v-else-if="fundraiser.likes?.isLiked"
          />
          <HeartIcon
            class="h-5 xs:h-6 fill-current text-text-secondary"
            v-else
          />
        </button>
      </div>
      <div class="px-4 py-2 flex-grow">
        <p class="text-xs text-text-alternate-3 font-medium my-1">
          Organised by
          <span class="text-text-alternate-1">
            {{ fundraiser.userdetail.firstname }}
            {{ fundraiser.userdetail.lastname }}
          </span>
        </p>
        <h2
          class="font-semibold md:text-lg text-text-alternate-1 my-2 truncate"
        >
          {{ fundraiser.title }}
        </h2>
        <div
          class="text-xs font-medium text-text-alternate-3 line-clamp-2"
          v-html="fundraiser.fundraiserDesc"
        ></div>
        <div class="py-2">
          <div
            :class="[
              'h-2 rounded-full my-2 overflow-hidden',
              fundraiser.isPromoted ? 'coverage' : 'bg-bg-alternate-3',
            ]"
          >
            <div
              class="bg-bg-alternate-8 rounded-full h-2"
              :style="{ width: targetCoverage }"
            ></div>
          </div>
          <p class="text-xs py-1 font-medium">
            Raised
            <span class="font-semibold text-text-alternate-4">
              ₦{{ fundraiser.raisedAmount.toLocaleString() }}
            </span>
            <template v-if="!fundraiser.hideCycleTargetAmount">
              of
              <span class="font-semibold text-text-alternate-6">
                ₦{{ fundraiser.targetAmount.toLocaleString() }}
              </span>
            </template>
          </p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { computed, watch, toRefs } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useApi } from '@/cmp-functions/useApi';
import { likeFundraiser } from '@/services/api';

export default {
  name: 'BFundraiserCardMD',

  emits: ['showMenu', 'like'],

  props: {
    fundraiser: {
      required: true,
    },

    showMenu: {
      default: false,
    },
  },

  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const authUser = computed(() => store.state.auth.authData);

    const [likeResponse, postLikeFundraiser] = useApi(likeFundraiser);

    const targetCoverage = computed(() => {
      const { raisedAmount, targetAmount } = props.fundraiser;

      return `${Math.min(raisedAmount / targetAmount, 1) * 100}%`;
    });

    const isOwner = computed(
      () => props.fundraiser.userId == authUser.value?.id
    );

    const canLike = computed(() => authUser.value && !isOwner.value);

    const slug = computed(() => {
      const { id, title } = props.fundraiser;
      return `${id}-${title
        .trim()
        .toLowerCase()
        .replace(/[^a-z\d\s:]/g, '')
        .replace(/\s+/g, '-')}`;
    });

    const handleLike = () => {
      if (!canLike.value) return;
      postLikeFundraiser({
        userId: authUser.value.id,
        id: props.fundraiser.id,
      });
    };

    watch(likeResponse, ({ isSuccess, data }) => {
      if (isSuccess) {
        emit('like', data);
      }
    });

    const featuredImage = computed(() => {
      const imgObj = props.fundraiser.images.filter((item) => item.isFeatured);
      if (imgObj.length) {
        return imgObj[0].imageurl;
      } else {
        return props.fundraiser.images[props.fundraiser.images?.length - 1]
          .imageurl;
      }
    });

    return {
      ...toRefs(likeResponse),
      targetCoverage,
      isOwner,
      router,
      slug,
      canLike,
      handleLike,
      featuredImage,
    };
  },
};
</script>

<style lang="scss" scoped>
.coverage {
  background: #bfcfda;
}
</style>
