<template>
  <BModal :isOpen="modelValue" @close="toggleOpenState">
    <BPageLoader v-if="isLoading || data?.link || response.isLoading" />
    <div
      class="flex flex-grow flex-col text-text-primary justify-center"
      v-else-if="isSuccess"
    >
      <div class="text-center pb-6 flex flex-col items-center justify-center">
        <SuccessIcon class="mb-8 mx-auto" />
        <h4 class="font-semibold text-lg">Congratulations</h4>
        <p class="text-sm text-text-primary mt-4">
          Your promotion payment was successful. Your fundraiser project will be reviewed by the admin in less than 48hrs.
        </p>
        <button
          class="bg-btn-bg-primary max-w-sm text-btn-text-primary rounded w-full mt-6 py-4 font-bold text-sm xs:text-base focus:outline-none"
          @click="toggleOpenState"
        >
          Ok
        </button>
      </div>
    </div>
    <div
      class="flex flex-grow flex-col text-text-primary justify-center"
      v-else-if="isFailure"
    >
      <div class="text-center pb-6 flex flex-col items-center justify-center">
        <FailureIcon class="mb-8 mx-auto" />
        <h2 class="text-lg font-semibold mt-2">Sorry!</h2>
        <p class="text-xs text-alternate-3 my-4">
          Promotion {{ error || 'failed' }}
        </p>
        <button
          class="bg-btn-bg-primary max-w-sm text-btn-text-primary rounded w-full mt-6 py-4 font-bold text-sm xs:text-base focus:outline-none"
          @click="toggleOpenState"
        >
          Ok
        </button>
      </div>
    </div>
    <div class="w-full" v-else-if="stage === 1">
      <p class="text-xs font-medium text-text-alternate-3 mb-6">Change the promotion plan for your fundraiser</p>
      <ul class="mb-24">
        <li v-for="promo in response.data" :key="promo.id">
          <label
            :class="[
              'block rounded p-4 xs:p-5 my-4 xs:my-5 relative border-box cursor-pointer',
              `promo-${promo.id}`,
              { active: type === promo.id },
            ]"
          >
            <TickIcon
              v-if="type === promo.id"
              class="h-4 xs:h-5 absolute right-4 top-4 z-10 fill-current"
              style="fill: #017622"
            />
            <h3
              class="font-semibold mb-2 text-sm xs:text-base text-text-alternate-1"
            >
              <span :class="['text-text-alternate-1 font-bold amount']">
                ₦{{ promo.amount.toLocaleString() }}
              </span>
              <span class="text-text-alternate-1 font-semibold text-sm mt-1">
                /{{ promo.plan }}
              </span>
            </h3>
            <p class="text-text-alternate-3 font-medium text-xs xs:text-sm">
              {{ promo.plandesc }}
            </p>
            <input
              type="radio"
              :name="promo.plan"
              :value="promo.id"
              hidden
              v-model="type"
            />
          </label>
        </li>
      </ul>
      <div class="px-6 py-4 flex gap-4 z-10 bg-bg-alternate-3 absolute left-0 w-full bottom-13">
        <button
          class="bg-btn-bg-primary text-btn-text-primary px-4 py-3.5 xs:py-4 w-full rounded text-center text-sm xs:text-base font-bold"
          :disabled="!type"
          @click="stage = 2"
        >
          Continue
        </button>
      </div>
    </div>
    <ul v-else-if="stage === 2">
      <li class="my-4">
        <button
          class="border border-btn-border-alternate-1 btn-shadow w-full rounded pt-4 pb-5 flex justify-center gap-5 items-center"
          @click="payWithWallet"
        >
          <WalletIcon class="h-8" />
          <div class="text-left flex flex-col gap-2">
            <p class="text-xs font-semibold text-text-alternate-1">Pay with Wallet</p>
            <p class="text-xs font-medium text-text-alternate-3">
              Balance: <span class="font-semibold">₦{{ walletBalance.toLocaleString() }}</span>
            </p>
          </div>
        </button>
      </li>
      <li class="my-4">
        <button
          class="border border-btn-border-alternate-1 btn-shadow w-full rounded py-3.5 sm:py-4"
          @click="processToPayment(2)"
        >
          <div class="flex justify-center gap-3 py-px">
            <MasterCardIcon class="h-5" />
            <VisaIcon class="h-5" />
            <VerveIcon class="h-5" />
          </div>
          <p class="text-xs font-semibold mt-2 text-text-alternate-1">Pay with Paystack</p>
        </button>
      </li>
      <li class="my-4">
        <button
          class="border border-btn-border-alternate-1 btn-shadow w-full rounded py-3.5 sm:py-4"
          @click="processToPayment(3)"
        >
          <div class="flex justify-center gap-3 py-px">
            <MasterCardIcon class="h-5" />
            <VisaIcon class="h-5" />
            <VerveIcon class="h-5" />
          </div>
          <p class="text-xs font-semibold mt-2 text-text-alternate-1">Pay with Monnify</p>
        </button>
      </li>
    </ul>
  </BModal>
</template>

<script>
import { ref, computed, watch, toRefs } from 'vue';
import BModal from './BModal';
import { useStore } from 'vuex';
import { useApi } from '@/cmp-functions/useApi';
import { getPromoPlans, makePayment } from '@/services/api';
import { useToast } from 'vue-toastification';

export default {
  name: 'PromotionModal',

  props: ['modelValue', 'fundraiserId'],

  emits: ['update:modelValue'],

  components: { BModal },

  setup(props, { emit }) {
    const type = ref('');
    const stage = ref(1);
    const toast = useToast();
    const [response, fetchPromoPlans] = useApi(getPromoPlans);
    fetchPromoPlans();

    const store = useStore();
    const walletBalance = computed(() => store.state.auth.userWallet.balance);
    const userDetails = computed(() => store.state.auth.userDetails);

    const [promotionResponse, makePromotionPayment] = useApi(makePayment);

    const selectedPromo = computed(() => response.data.find(({ id }) => id === type.value));

    const processToPayment = (payType) => {
      const { userid, firstname, lastname, email } = userDetails.value;
      const payload = {
        userId: userid,
        firstname,
        lastname,
        email,
        type: payType,
        fundraiserId: props.fundraiserId,
        amount: selectedPromo.value.amount,
      };

      makePromotionPayment(payload, { promotionId: type.value });
    };

    watch(promotionResponse, ({ data, isSuccess }) => {
      if (!isSuccess) return;
      if (data?.link) {
        window.location.href = data.link;
      } else {
        store.dispatch('auth/fetchUserWallet', userDetails.value.userid);
      }
    });

    const payWithWallet = () => {
      if (selectedPromo.value.amount > walletBalance.value) {
        toast.error('Insufficient Balance');
      } else {
        processToPayment(4);
      }
    };

    const toggleOpenState = () => {
      if (promotionResponse.isLoading) return;
      type.value = '';
      stage.value = 1;
      promotionResponse.isSuccess = false;
      promotionResponse.isFailure = false;
      emit('update:modelValue', !props.modelValue);
    };

    return {
      type,
      stage,
      ...toRefs(promotionResponse),
      response,
      processToPayment,
      walletBalance,
      payWithWallet,
      toggleOpenState,
    };
  },
};
</script>


<style lang="scss" scoped>
.promo-1 {
  background: #E0F2FF;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;

  &.active {
    border-color:#567286;
  }
  .amount {
    color: #567286;
  }
}

.promo-2 {
  background: #FEEDC8;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;

  &.active {
    border-color:#9F771F;
  }
  .amount {
    color: #9F771F;
  }
}

.promo-3 {
  background: #FDE0D4;
  border-color: transparent;
  border-width: 1px;
  border-style: solid;

  &.active {
    border-color: #D15520;
  }
  .amount {
    color: #D15520;
  }
}
</style>
