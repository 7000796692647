<template>
  <div>
    <div class="lg:hidden">
      <div class="px-6 py-2 flex flex-col font-semibold lg:mt-32">
        <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
          <button class="focus:outline-none" @click="$router.go(-1)">
            <LeftArrowIcon class="h-6 fill-current" />
          </button>
          <h2 class="mx-auto">My Fundraisers</h2>
        </header>
        <BPageLoader
          v-if="isLoading && !data.fundraisers?.length"
          class="flex-grow"
        />
        <div
          v-else-if="!data.fundraisers?.length"
          class="flex flex-grow flex-col justify-center items-center"
        >
          <span
            class="
              h-24
              w-24
              rounded-full
              bg-bg-alternate-3
              flex
              items-center
              justify-center
              my-6
            "
          >
            <Fundraiser2Icon
              class="h-8 stroke-2 stroke-current text-text-primary"
            />
          </span>
          <h4 class="my-4 text-lg">No Active Fundraiser</h4>
          <p class="text-xs font-medium text-center">
            You have no active fundraisers, please click the button below to
            start
          </p>
          <router-link
            class="
              font-bold
              mx-auto
              w-full
              my-6
              md:my-8
              py-3
              xs:py-4
              text-center text-btn-text-primary
              bg-btn-bg-primary
              inline-block
              rounded
            "
            :to="{ name: 'CreateProject' }"
            >Create Fundraiser</router-link
          >
        </div>
        <main v-else class="py-4 xs:py-5">
          <div class="grid xs:grid-cols-2 gap-5">
            <BFundraiserCardMD
              v-for="fundraiser in data.fundraisers"
              :key="fundraiser.id"
              :fundraiser="fundraiser"
              :showMenu="true"
              @showMenu="handleShowMenu"
            />
          </div>
          <BPageLoader v-if="isLoading" />
          <router-link
            class="
              font-bold
              mx-auto
              w-full
              my-6
              md:my-8
              py-4
              text-center text-btn-text-primary
              bg-btn-bg-primary
              inline-block
              rounded
            "
            :to="{ name: 'CreateProject' }"
            >Create Fundraiser</router-link
          >
        </main>
      </div>
    </div>
    <div class="hidden lg:flex justify-center pt-4">
      <div class="b-card mt-24 pt-8">
        <div class="px-26 py-2 flex flex-col font-semibold">
          <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
            <button class="focus:outline-none" @click="$router.go(-1)">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto">My Fundraisers</h2>
          </header>
          <BPageLoader
            v-if="isLoading && !data.fundraisers?.length"
            class="flex-grow"
          />
          <div
            v-else-if="!data.fundraisers?.length"
            class="flex flex-grow flex-col justify-center items-center"
          >
            <span
              class="
                h-24
                w-24
                rounded-full
                bg-bg-alternate-3
                flex
                items-center
                justify-center
                my-6
              "
            >
              <Fundraiser2Icon
                class="h-8 stroke-2 stroke-current text-text-primary"
              />
            </span>
            <h4 class="my-4 text-lg">No Active Fundraiser</h4>
            <p class="text-xs font-medium text-center">
              You have no active fundraisers, please click the button below to
              start
            </p>
            <router-link
              class="
                font-bold
                mx-auto
                w-full
                my-6
                md:my-8
                py-3
                xs:py-4
                text-center text-btn-text-primary
                bg-btn-bg-primary
                inline-block
                rounded
              "
              :to="{ name: 'CreateProject' }"
              >Create Fundraiser</router-link
            >
          </div>
          <main v-else class="py-4 xs:py-5">
            <div class="grid xs:grid-cols-2 gap-5">
              <!-- {{authUser}} -->

              <BFundraiserCardMD
                v-for="fundraiser in data.fundraisers"
                :key="fundraiser.id"
                :fundraiser="fundraiser"
                :showMenu="true"
                @showMenu="handleShowMenu"
              />
            </div>
            <BPageLoader v-if="isLoading" />
            <router-link
              class="
                font-bold
                mx-auto
                w-full
                my-6
                md:my-8
                py-4
                text-center text-btn-text-primary
                bg-btn-bg-primary
                inline-block
                rounded
              "
              :to="{ name: 'CreateProject' }"
              >Create Fundraiser</router-link
            >
          </main>
        </div>
      </div>
    </div>
  </div>
  <BModal :isOpen="showPickMenu" @close="showPickMenu = false">
    <ul class="text-text-primary font-semibold text-sm w-full">
      <li class="my-1 xs:my-3">
        <router-link
          class="
            py-2
            w-full
            inline-block
            hover:bg-bg-alternate-5
            text-sm
            font-semibold
          "
          :to="{ name: 'EditFundraiser', params: { id: selectedFundraiserId } }"
        >
          Edit
        </router-link>
      </li>
      <li class="my-1 xs:my-3">
        <router-link
          class="
            py-2
            w-full
            inline-block
            hover:bg-bg-alternate-5
            text-sm
            font-semibold
          "
          :to="{
            name: 'fundraiserHistory',
            params: { id: selectedFundraiserId },
          }"
        >
          Fundraiser History
        </router-link>
      </li>
      <li
        class="
          py-2
          my-1
          xs:my-3
          w-full
          cursor-pointer
          relative
          hover:bg-bg-alternate-5
          text-sm
          font-semibold
        "
        @click="handlePromote"
      >
        Promote
      </li>
      <li
        class="
          py-2
          my-1
          xs:my-3
          w-full
          cursor-pointer
          relative
          hover:bg-bg-alternate-5
          text-sm
          font-semibold
        "
        @click="handleEmbed"
      >
        Embed
      </li>
      <li class="my-1 xs:my-3">
        <button
          class="
            py-2
            w-full
            inline-block
            hover:bg-bg-alternate-5
            text-sm
            font-semibold
            focus:outline-none
            text-left
          "
          @click="handleDelete"
        >
          Delete
        </button>
      </li>
    </ul>
  </BModal>
  <BModal :isOpen="isDelete" @close="isDelete = false">
    <div class="text-center flex flex-col items-center">
      <FailureIcon class="my-4 mx-auto" />
      <h2 class="text-lg text-text-primary font-semibold my-4">
        Delete Fundraiser?
      </h2>
      <p class="text-xs text-text-alternate-3 font-medium px-6">
        Deleting a fundraiser means that your fundraiser would be removed
        entirely from our system. All blessings received will be automatically
        transferred to your account upon verification by the admin. This action
        can’t be undone.
      </p>
      <button
        class="
          bg-btn-bg-primary
          max-w-lg
          text-btn-text-primary
          rounded
          w-full
          mt-6
          py-4
          font-bold
          text-sm
          xs:text-base
          focus:outline-none
        "
        @click="deleteThisFundraiser"
        :disabled="delResponse.isLoading"
      >
        <BButtonLoader class="h-4 w-4 mx-auto" v-if="delResponse.isLoading" />
        <span v-else>Delete</span>
      </button>
      <button
        class="
          text-text-primary
          focus:outline-none
          my-6
          rounded
          text-center text-sm
          xs:text-base
          font-bold
        "
        @click="isDelete = false"
      >
        Cancel
      </button>
    </div>
  </BModal>
  <EmbedModal v-model="isEmbed" :slug="slug" />
  <PromotionModal v-model="isPromote" :fundraiserId="selectedFundraiserId" />
</template>

<script>
import { toRefs, ref, watch, computed } from 'vue';
import BFundraiserCardMD from '@/components/ui/BFundraiserCardMD';
import { useApi } from '@/cmp-functions/useApi';
import { useInfiniteScroll } from '@/cmp-functions/useInfiniteScroll';
import { getUserFundraisers, deleteFundraiser } from '@/services/api';
import { useStore } from 'vuex';
import EmbedModal from '@/components/ui/EmbedModal';
import PromotionModal from '@/components/ui/PromotionModal';
import BModal from '@/components/ui/BModal';

const handlePaginationResponse = (pre, { data }) => {
  if (!data?.data) return pre;
  const { data: fundraisers, metadata } = data;

  return {
    fundraisers: [...pre.fundraisers, ...fundraisers],
    metaData: {
      offset: fundraisers.length + pre.fundraisers.length,
      ...metadata,
    },
  };
};

export default {
  name: 'AccMyFundraisers',

  components: { BFundraiserCardMD, EmbedModal, BModal, PromotionModal },

  setup() {
    const store = useStore();
    const showPickMenu = ref(false);
    const isDelete = ref(false);
    const isEmbed = ref(false);
    const isPromote = ref(false);
    const selectedFundraiserId = ref(null);

    const authUser = store.state.auth.authData;
    const [response, fetchUserFundraisers] = useApi(
      getUserFundraisers,
      {
        fundraisers: [],
        metaData: { offset: 0, isLastPage: false },
      },
      handlePaginationResponse
    );

    const fetchFundraiserData = () => {
      if (response.data.metaData.isLastPage || response.isLoading) return;
      fetchUserFundraisers({
        userId: authUser.id,
        offset: response.data.metaData.offset,
      });
    };

    useInfiniteScroll(fetchFundraiserData, true);

    const [delResponse, removeFundraiser] = useApi(deleteFundraiser);

    const handleEmbed = () => {
      isEmbed.value = true;
      showPickMenu.value = false;
    };

    const handlePromote = () => {
      isPromote.value = true;
      showPickMenu.value = false;
    };

    const handleDelete = () => {
      isDelete.value = true;
      showPickMenu.value = false;
    };

    const handleShowMenu = (id) => {
      showPickMenu.value = true;
      selectedFundraiserId.value = id;
    };

    const deleteThisFundraiser = () => {
      removeFundraiser(selectedFundraiserId.value);
    };

    watch(delResponse, ({ isSuccess }) => {
      if (isSuccess) {
        isDelete.value = false;
        const index = response.data.fundraisers.findIndex(
          ({ id }) => id === selectedFundraiserId.value
        );
        response.data.fundraisers.splice(index, 1);
      }
    });

    const slug = computed(() => {
      const { id, title } =
        response.data?.fundraisers?.find(
          (fund) => fund.id === selectedFundraiserId.value
        ) || {};

      return `${id}-${title
        ?.trim()
        ?.toLowerCase()
        ?.replace(/[^a-z\d\s:]/g, '')
        ?.replace(/\s+/g, '-')}`;
    });

    return {
      ...toRefs(response),
      delResponse,
      slug,
      authUser,
      showPickMenu,
      isDelete,
      isEmbed,
      isPromote,
      handleShowMenu,
      handlePromote,
      selectedFundraiserId,
      deleteThisFundraiser,
      handleDelete,
      handleEmbed,
    };
  },
};
</script>
