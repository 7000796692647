<template>
  <BModal :heading="'Embed'" :isOpen="modelValue" @close="toggleOpenState">
    <div class="my-2 mx-auto max-w-l lg:pt-6">
      <div
        class="
          rounded
          bg-bg-alternate-3
          text-text-primary
          font-semibold
          p-4
          text-xs
          break-all
        "
      >
        {{ embeded }}
      </div>
      <button
        class="
          bg-btn-bg-primary
          text-btn-text-primary
          rounded
          w-full
          mt-6
          py-4
          font-bold
          text-sm
          focus:outline-none
        "
        @click="handleCopy"
      >
        Copy Embed Code
      </button>
    </div>
  </BModal>
</template>

<script>
import { ref, computed } from 'vue';
import BModal from './BModal';
import { useToast } from 'vue-toastification';

export default {
  name: 'EmbedModal',

  props: ['modelValue', 'slug', 'wishListId', 'promoteAndEarnId'],

  emits: ['update:modelValue'],

  components: { BModal },

  setup(props, { emit }) {
    const openState = ref(!false);
    const toast = useToast();

    const embeded = computed(() => {
      const url = `${process.env.VUE_APP_BLESS_EMBED_URL}/${props.slug}?${
        props.wishListId ? `wishlist_id=${props.wishListId}` : ``
      }${
        props.promoteAndEarnId ? `promotion_id=${props.promoteAndEarnId}` : ``
      }`;
      return `<iframe width="100%" height="580px" src="${url}" frameborder="0"></iframe>`;
    });

    const handleCopy = async () => {
      try {
        navigator.clipboard.writeText(embeded.value);
        toast.success('Copied to clipboard!');
        toggleOpenState();
      } catch (error) {
        console.log(error);
      }
    };

    const toggleOpenState = () => {
      emit('update:modelValue', !props.modelValue);
    };

    return { openState, toggleOpenState, embeded, handleCopy };
  },
};
</script>
